<template lang="pug">
  .flipbook-wapper(
    :class="{ 'has-mouse': hasMouse, 'has-shadow' : hasShadow }" 
    @touchstart='hasMouse = false'
  )
    audio(ref="audio" style="display: none")
      source(src="../assets/open-book.mp3" type="audio/mpeg")
    flipbook.flipbook(
      :pages='pages'
      :startPage='pageNum'
      :flipDuration="1300"
      :zooms="[1, 2]"
      v-slot='flipbook'
      ref='flipbook'
      @flip-left-start='onFlipLeftStart'
      @flip-left-end='onFlipLeftEnd'
      @flip-right-start='onFlipRightStart'
      @flip-right-end='onFlipRightEnd'
      @zoom-start='onZoomStart'
      @zoom-end='onZoomEnd'
    )
      .action-bar
        left-icon.btn.left(
          :class='{ disabled: !flipbook.canFlipLeft }'
          @click='flipbook.flipLeft'
        )
        plus-icon.btn.plus(
          :class='{ disabled: !flipbook.canZoomIn }'
          @click='flipbook.zoomIn'
        )
        span.page-num
          | Page {{ flipbook.page }} of {{ flipbook.numPages }}
        minus-icon.btn.minus(
          :class='{ disabled: !flipbook.canZoomOut }'
          @click='flipbook.zoomOut'
        )
        right-icon.btn.right(
          :class='{ disabled: !flipbook.canFlipRight }'
          @click='flipbook.flipRight'
        )
</template>

<script>
import Flipbook from "flipbook-vue";
import LeftIcon from "vue-material-design-icons/ChevronLeftCircle";
import RightIcon from "vue-material-design-icons/ChevronRightCircle";
import PlusIcon from "vue-material-design-icons/PlusCircle";
import MinusIcon from "vue-material-design-icons/MinusCircle";

export default {
  components: { Flipbook, LeftIcon, RightIcon, PlusIcon, MinusIcon },
  props: {
    images: {
      type: Array,
      default: () => [],
      require: true,
    },
  },
  data: () => ({
    pages: [],
    pagesHiRes: [],
    hasMouse: true,
    hasShadow: false,
    pageNum: null
  }),

  methods: {
    playAudio() {
      const audio = document.querySelector('audio')
      // const audio = this.$refs.audio
      audio.play();
    },
    changePage() {
      this.playAudio()
      this.hasShadow = false;
      // this.$refs.audio.play();
      // const audio = document.querySelector('audio')
      // const audio = new Audio(require('../assets/open-book.mp3'));
      // audio.play()
    },

    onFlipLeftStart(page) {
      this.changePage();
    },
    onFlipLeftEnd(page) {
      if (page !== 1) this.hasShadow = true;
      // return (window.location.hash = "#" + page);
    },
    onFlipRightStart(page) {
      this.changePage();
    },
    onFlipRightEnd(page) {
      if (page !== this.$refs.flipbook.numPages) this.hasShadow = true;
      // return (window.location.hash = "#" + page);
    },
    onZoomStart(zoom) {},
    onZoomEnd(zoom) {},
    setPageFromHash() {
      let n;
      n = parseInt(window.location.hash.slice(1), 10);
      if (isFinite(n)) {
        return (this.pageNum = n);
      }
    }
  },

  mounted() {
    window.addEventListener("keydown", ev => {
      const flipbook = this.$refs.flipbook;
      if (!flipbook) {
        return;
      }
      if (ev.keyCode === 37 && flipbook.canFlipLeft) {
        flipbook.flipLeft();
      }
      if (ev.keyCode === 39 && flipbook.canFlipRight) {
        return flipbook.flipRight();
      }
    });

    window.addEventListener('touchstart', ev => {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if (isIOS) this.playAudio()
    }, {once: true})

    /* eslint-disable no-undef */
    setTimeout(() => {
      this.pages = this.images;
    }, 1);
    // window.addEventListener("hashchange", this.setPageFromHash);
    // return this.setPageFromHash();
  }
};
</script>

<style lang="scss">
a {
  color: inherit;
}

.flipbook-wapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.action-bar {
  width: 100%;
  height: 30px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;

  .btn {
    font-size: 30px;
    line-height: 20px;
    color: #999;

    svg {
      width: 2rem;
      height: 2rem;
      bottom: 0;
    }

    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}

.has-mouse .action-bar .btn:hover {
  color: #ccc;
  filter: drop-shadow(1px 1px 5px #000);
  cursor: pointer;
}

.action-bar {
  .btn {
    &:active {
      filter: none !important;
    }

    &.disabled {
      color: #666;
      pointer-events: none;
    }
  }

  .page-num {
    font-size: 12px;
    margin-left: 10px;
  }
}

.flipbook {
  .viewport {
    width: 90vw !important;
    height: calc(100vh - 50px - 40px) !important;
  }
}

.bounding-box {
  box-shadow: 0 0 20px #000;
}

@media screen and (orientation: landscape) {
  .click-to-flip.right {
    &::before {
      position: absolute;
      width: 100px;
      left: 0;
      top: 0;
      bottom: 0;
      content: "";
      margin-right: -50px;
      opacity: 0;
      z-index: 1;
      transition: opacity 0.2s;
      background: linear-gradient(
        90deg,
        rgba(51, 51, 51, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );

      .has-shadow & {
        opacity: 0.1;
      }
    }
  }
}
</style>